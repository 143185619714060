const language_es = {
  dashboard: "Tablero",
  layoutBuilder: "Constructor de maquetación",
  craft: "Elaborado",
  pages: "Paginas",
  profile: "Perfil",
  profileOverview: "Descripción general",
  projects: "Proyectos",
  campaigns: "Campañas",
  documents: "Documentos",
  connections: "Conexiones",
  wizards: "Magos",
  horizontal: "Horizontal",
  vertical: "Vertical",
  account: "Cuenta",
  accountOverview: "Descripción general",
  settings: "Ajustes",
  authentication: "Autenticación",
  basicFlow: "Flujo básico",
  signIn: "Registrarse",
  signUp: "Inscribirse",
  passwordReset: "Restablecimiento de contraseña",
  multiStepSignUp: "Regístrese Multi-Pasos",
  error404: "Error 404",
  error500: "Error 500",
  apps: "Aplicaciones",
  chat: "Chat",
  privateChat: "Chat privado",
  groupChat: "Grupo de chat",
  drawerChat: "Chat del cajón",
  widgets: "Widgets",
  widgetsLists: "Liza",
  widgetsStatistics: "Estadísticas",
  widgetsCharts: "Gráficos",
  widgetsMixed: "Mezclada",
  widgetsTables: "Mesas",
  widgetsFeeds: "Alimenta",
  changelog: "Registro de cambios",
  docsAndComponents: "Documentos & Componentes",
  megaMenu: "Mega menú",
  exampleLink: "Enlace de ejemplo",
  modals: "Modales",
  general: "General",
  inviteFriends: "Invitar A Amigos",
  viewUsers: "Ver Usuarios",
  upgradePlan: "Plan De Actualización",
  shareAndEarn: "Compartir Y Ganar",
  forms: "Formas",
  newTarget: "Nuevo Objetivo",
  newCard: "Nueva Tarjeta",
  newAddress: "Nueva Direccion",
  createAPIKey: "Crea Clave De Api",
  twoFactorAuth: "Dos Factores",
  createApp: "Crear Aplicacion",
  createAccount: "Crear Una Cuenta",
  activity: "Actividad",
  documentation: "Documentación",
  components: "Componentes",
  resources: "Recursos",
  customers: "Clientes",
  gettingStarted: "Empezando",
  customersListing: "Listado De Clientes",
  customerDetails: "Detalles De Los Clientes",
  calendarApp: "Calendario",
  subscriptions: "Suscripciones",
  getStarted: "Empezando",
  subscriptionList: "Lista De Suscripción",
  addSubscription: "Añadir Suscripción",
  viewSubscription: "Suscripción",
  /* extractSpace */
  login_continue: "Seguir",
  invoice_enhancement: "Mejora de las factura",
  invoice_list: "Lista de facturas",
  subscription_plan: "Plan de suscripción",
  profile_update_success: "¡Perfil actualizado con éxito!",
};

export default language_es;
