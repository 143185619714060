const language_fr = {
  dashboard: "Générateur de mise",
  layoutBuilder: "En page",
  craft: "Fabriqué",
  pages: "Pages",
  profile: "Profil",
  profileOverview: "Aperçu",
  projects: "Projets",
  campaigns: "Campagnes",
  documents: "Documents",
  connections: "Connexions",
  wizards: "Sorciers",
  horizontal: "Horizontal",
  vertical: "Verticale",
  account: "Compte",
  accountOverview: "Aperçu",
  settings: "Paramètres",
  authentication: "Authentification",
  basicFlow: "Flux de base",
  signIn: "SS'identifier",
  signUp: "Inscrivez-vous",
  passwordReset: "Réinitialisation du mot de passe",
  multiStepSignUp: "S'Inscrire Multi-Étapes",
  error404: "Erreur 404",
  error500: "Erreur 500",
  apps: "Applications",
  chat: "Discuter",
  privateChat: "Discussion privée",
  groupChat: "Discussion de groupe",
  drawerChat: "Chat de tiroir",
  widgets: "Widgets",
  widgetsLists: "Listes",
  widgetsStatistics: "Statistiques",
  widgetsCharts: "Graphiques",
  widgetsMixed: "Mixte",
  widgetsTables: "Les tables",
  widgetsFeeds: "Flux",
  changelog: "Journal des modifications",
  docsAndComponents: "Documents & composants",
  megaMenu: "Méga Menu",
  exampleLink: "Exemple de lien",
  modals: "Modals",
  general: "Général",
  inviteFriends: "Inviter Des Amis",
  viewUsers: "Voir Les Utilisateurs",
  upgradePlan: "Plan De Mise À Niveau",
  shareAndEarn: "Partager Et Gagner",
  forms: "Formes",
  newTarget: "Nouvelle Cible",
  newCard: "Nouvelle Carte",
  newAddress: "Nouvelle Adresse",
  createAPIKey: "Créer Une Touche Api",
  twoFactorAuth: "Deux Facteurs D'Authentification",
  createApp: "Créer Une Application",
  createAccount: "Créer Un Compte",
  activity: "Activité",
  documentation: "Documentation",
  components: "Composants",
  resources: "Ressources",
  customers: "Les Clients",
  gettingStarted: "Commencer",
  customersListing: "Liste Des Clients",
  customerDetails: "Détails Des Clients",
  calendarApp: "Calendrier",
  subscriptions: "Abonnements",
  getStarted: "Commencer",
  subscriptionList: "Liste D'Abonnement",
  addSubscription: "Ajouter Un Abonnement",
  viewSubscription: "Voir L'Abonnement",
  /* extractSpace */
  login_continue: "Continuer",
  invoice_enhancement: "Améliorer",
  invoice_list: "Liste des factures",
  subscription_plan: "Formules d'abonnement",
};

export default language_fr;
