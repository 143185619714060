import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import UserModule from '@/store/modules/UserModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import ThemeModeModule from '@/store/modules/ThemeModeModule';

config.rawError = true;

const store = createStore({
  modules: {
    UserModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ThemeModeModule,
  },
});

export default store;
