import { createI18n } from 'vue-i18n';
import language_en from './translations/en.js';
import language_es from './translations/es.js';
import language_de from './translations/de.js';
import language_fr from './translations/fr.js';
import language_jp from './translations/jp.js';
import language_it from './translations/it.js';

const messages = {
  en: language_en,
  es: language_es,
  de: language_de,
  fr: language_fr,
  ja: language_jp,
  it: language_it,
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  messages,
});

export default i18n;
